import { useEffect, useState } from 'react'

import { Button, ButtonWhatsapp, Icon, SkeletonLoader } from '@/components'

import { BUTTONS_DATA } from './constants'

import styles from './NavigationButtons.module.scss'

export const NavigationButtons = ({ closeMenu }) => {
  const [isLoading, setIsLoading] = useState(true)

  const buildType = NEXT_PUBLIC_BUILD_TYPE ?? 'paid' // eslint-disable-line no-undef
  const isOrganic = buildType === 'organic'

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <>
      {isLoading && (
        <SkeletonLoader.Single
          height={46}
          customClass={styles.buttons__loader}
        />
      )}

      {!isLoading && (
        <div className={styles.buttons}>
          {BUTTONS_DATA[buildType].map(
            ({ id, variant, icon, text, rel, target, url }) => (
              <Button
                tagName="a"
                id={id}
                key={id}
                rel={rel}
                target={target}
                href={url}
                variant={variant}
                onClick={() => closeMenu()}
              >
                {icon && <Icon name={icon} size="small" />}
                {text}
              </Button>
            )
          )}

          {!isOrganic && (
            <ButtonWhatsapp id="header-whatsapp-button">
              Contrate pelo WhatsApp
            </ButtonWhatsapp>
          )}
        </div>
      )}
    </>
  )
}
