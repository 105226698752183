'use client'

import classnames from 'classnames'

import { ActionToggler, Badge, Button, Divider, Icon, If } from '..'

import Radio from '../Form/Vanilla/Radio'

import _formatters from '@/utils/formatters'

import { Details } from './components'

import { usePlan } from './usePlan'

import styles from './Plan.module.scss'

const defaultAction = {
  id: 'plan-seeDetails-button',
  text: 'Ver detalhes',
  leftIcon: 'equifax-finance-chip',
  rightIcon: 'equifax-arrow-down'
}

export const Plan = ({
  reference,
  children,
  icon,
  plan = {},
  customClass,
  selectedPlan,
  expandedPlans = {},
  action = defaultAction,
  isActive,
  hasCTA,
  setExpandedPlans,
  openPaymentDetails,
  onClickCard
}) => {
  const {
    planBaseRoute,
    loadingButtonID,
    onClickAction,
    onSelectPlan,
    setLoadingButtonID
  } = usePlan({
    plan,
    action,
    setExpandedPlans,
    onClickCard
  })

  return (
    <>
      <div
        ref={reference}
        id={`plan-${plan.plan_price}-card`}
        onClick={onSelectPlan}
        className={classnames(styles.plan, {
          [styles['plan--withCta']]: hasCTA,
          ['--gtm-click']: onClickCard,
          [styles['plan--clickable']]: onClickCard,
          [styles['plan--active']]:
            isActive || selectedPlan === plan.plan_price,
          [customClass]: customClass
        })}
      >
        <div className={styles.plan__heading}>
          <div>
            <If condition={icon}>
              <Icon
                name={icon}
                size="small"
                color="white"
                className={styles.plan__icon}
              />
            </If>

            <label htmlFor="plan">{plan.name}</label>
          </div>

          <If condition={plan.badge_text}>
            <Badge
              round
              color="red"
              icon="equifax-stars"
              text={plan.badge_text}
              customClass={styles.plan__badge}
            />
          </If>

          <If condition={onClickCard}>
            <Radio
              name="plan_price"
              value={plan.plan_price}
              checked={selectedPlan === plan.plan_price}
              onChange={(_, value) => onClickCard(value)}
            />
          </If>
        </div>

        <If condition={plan.discount}>
          <p className={styles.plan__discount}>
            Economize {plan.discount} por consulta
          </p>
        </If>

        <div
          className={`${styles.plan__content} ${styles[`plan__content--${plan.plan_price}`]} ${!plan.discount && styles['plan__content--emptyDiscount']}`}
        >
          <div>
            <p className={styles.plan__highlightText}>
              {_formatters.toCurrency(plan.plan_price)}
            </p>

            <p className={styles.plan__subscription}>assinatura mensal</p>

            <p className={styles.plan__text}>
              Média de{' '}
              <b>{plan.monthly_consultations.toLocaleString()} consultas</b> por
              mês
            </p>

            <Divider />

            <p className={styles.plan__text}>
              Consultas de CNPJ a partir de{' '}
              <b>{_formatters.toCurrency(plan.define_unit_value.risco)}</b>
              <br />
              Consultas de CPF a partir de{' '}
              <b>{_formatters.toCurrency(plan.acerta_unit_value.essencial)}</b>
            </p>

            <p className={styles.plan__text}>
              <b>Pagamento via boleto</b>
            </p>

            <ActionToggler
              {...action}
              size="small"
              id={`${action.id}--${plan.plan_price}`}
              isExpanded={expandedPlans[plan.plan_price]}
              onClick={onClickAction}
            />

            <Details
              plan={plan}
              isExpanded={expandedPlans[plan.plan_price]}
              openPaymentDetails={openPaymentDetails}
            />
          </div>

          {children}

          <If condition={hasCTA}>
            <Button
              tagName="a"
              variant="primary"
              id={`plan-wantToHire-button--${plan.plan_price}`}
              href={`${planBaseRoute}?plano=${plan.slug_name}`}
              isLoading={loadingButtonID === plan.plan_price}
              onClick={() => setLoadingButtonID(plan.plan_price)}
            >
              Quero contratar
            </Button>
          </If>
        </div>
      </div>
    </>
  )
}
