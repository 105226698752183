'use client'

import CepField from './Input/Cep'
import NumberField from './Input/Number'
import CnpjField from './Input/Cnpj'
import CpfField from './Input/Cpf'
import DateField from './Input/Date'
import EmailField from './Input/Email'
import PhoneField from './Input/Phone'
import TextField from './Input/Text'

import CheckboxField from './Checkbox'

import SelectSearchField from './Select'

export const Input = {
  Cep: CepField,
  Cnpj: CnpjField,
  Cpf: CpfField,
  Date: DateField,
  Email: EmailField,
  Number: NumberField,
  Phone: PhoneField,
  Text: TextField
}

export const Select = SelectSearchField
export const Checkbox = CheckboxField
