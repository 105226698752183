import { Icon } from '..'

import styles from './ActionToggler.module.scss'

export const ActionToggler = ({
  id,
  size,
  text,
  leftIcon,
  rightIcon,
  isExpanded,
  onClick
}) => {
  const actionTogglerClasses = `
    ${styles.actionToggler} 
    ${styles[`actionToggler--${size}`]}
  `

  const rightIconClasses = `
    ${styles.actionToggler__rightIcon}
    ${isExpanded && styles['actionToggler__rightIcon--up']}
  `

  const onClickActionToggler = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onClick && onClick()
  }

  return (
    <button
      id={id}
      className={actionTogglerClasses}
      onClick={onClickActionToggler}
    >
      {leftIcon && <Icon size="xs" name={leftIcon} />}

      {text}

      <Icon size="xs" name={rightIcon} className={rightIconClasses} />
    </button>
  )
}
